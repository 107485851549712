import styled from "styled-components";
import { css } from "styled-components";
import { Rem, BP } from "../../commons/Theme";

export const StyledFastChooseItems = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: ${Rem(24)};
  padding-left: ${Rem(16)};
  overflow-x: auto;

  @media (${BP.ipad}) {
    justify-content: center;
    flex-wrap: wrap;
    column-gap: ${Rem(50)};
    max-width: 1024px;
  }
`;

export const StyledHiveCard = styled.div`
  background-color: white;
  margin-top: ${Rem(20)};
  margin-bottom: ${Rem(50)};

  ${(props) =>
    props.color
    && css`
    .group {
      .bg-3bee-orange-700,
      .bg-3bee-orange-500,
      .bg-3bee-green-500,
      .bg-3bee-pink-500 {
        background-color: ${props.color};
      }
    
      .text-3bee-orange-700,
      .text-3bee-green-700 {
        color: ${props.color};

        a {
          color: ${props.color} !important;
        }
      }
    
      button {
        background-color: ${props.color} !important;
        --tw-ring-color: ${props.color} !important;
    
        &:hover {
          color: white !important;
        }
      }
    }
  `}
`;

export const StyledContainer= styled.div`
`;

export const StyledButtonCTA = styled.div`
  display: flex; 
  justify-content: center; 
  margin-top: 40px;
  @media (${BP.ipad}) {
    margin-top: 50px;
  }
`;